
import store from '@/store';
import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';

interface FormState {
  email: string;
  password: string;
}


@Options({
  components: {
  },
})
export default class SigninView extends Vue {
  registerActive = false;

  formState = reactive<FormState>({
    email: '',
    password: '',
  });


  onFinish(param?: any) {
    console.log('on finish', param);
    store.dispatch('user/login', param);
  }

  onFinishFailed(param?: any) {
    console.log('on finish failed');
  }
}
