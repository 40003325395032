
import { Options } from 'vue-class-component';
import store from '@/store';
import AuthenticatorView from '../AuthenticatorView';
import { mapState } from 'vuex';
import PlayWithSubtitle from '@/components/common/PlayWithSubtitle.vue';
import TimerView from '@/components/common/TimerView.vue';
import { normalizeUrl } from '@/classes/utils/text.utils';

@Options({
  components: {
    PlayWithSubtitle,
    TimerView,
  },
  computed: { ...mapState(['playlist', 'baseUrl', 'token']) },
})
export default class PlaylistDetailView extends AuthenticatorView {
  playlist!: any;
  baseUrl!: string;
  bodyHeight = 500;
  isStop = false;
  activeKey = '1'


  toUrl() {
    return normalizeUrl(`${this.baseUrl}${this.playlist.currentLesson?.audioPath}`);
  }

  async onCustomCreated() {
    store.dispatch('playlist/getDetail', this.$route.params.id);
    this.bodyHeight = screen.availHeight - 300;
  }

  selectLesson(index: number) {
    store.commit('playlist/selectLesson', index);
    this.activeKey = '1';
  }

  stopAudio(){
    console.log('stop');
    this.isStop = true;
  }

  onEnded() {
    let index = this.playlist.index + 1;
    if (index > this.playlist.lessons.length - 1) {
      index = 0;
    }
    store.commit('playlist/selectLesson', index);
  }

}

