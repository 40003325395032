import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "about" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Timer = _resolveComponent("Timer")!
  const _component_PlayWithSubtitle = _resolveComponent("PlayWithSubtitle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, "Lesson " + _toDisplayString(_ctx.lesson.data?.name), 1),
    _createVNode(_component_PlayWithSubtitle, {
      playerHeight: `${_ctx.bodyHeight}px`,
      audioUrl: _ctx.toUrl(),
      sentences: _ctx.lesson.data?.subtitle,
      repeat: true,
      autoStart: true,
      id: _ctx.lesson.data?._id,
      isStop: _ctx.isStop
    }, {
      timer: _withCtx(() => [
        _createVNode(_component_Timer, {
          callback: _ctx.stopAudio,
          param: "123"
        }, null, 8, ["callback"])
      ]),
      _: 1
    }, 8, ["playerHeight", "audioUrl", "sentences", "id", "isStop"])
  ]))
}