import { createStore } from 'vuex'
import { UserModule } from './user'
import { ToastPluginApi } from 'vue-toast-notification';
import { LessonsModule } from './lessons';
import { LessonModule } from './lesson';
import { PlayListModule } from './playlists';
import { PlaylistModule } from './playlist';
import { DevicesModule } from './devices';

export default createStore({

  state: {
    baseUrl: '',
    token: undefined,
    toast: undefined as any,
  },
  getters: {
    baseUrl(state) {
      if (state.baseUrl) {
        const baseUrl = process.env.VUE_APP_ROOT_API;
        return baseUrl;
      }
      return state.baseUrl;
    }
  },
  mutations: {
    setBaseUrl(state, baseUrl) {
      state.baseUrl = baseUrl;
    },
    setToken(state, token) {
      state.token = token;
    },
    setToast(state, toast) {
      state.toast = toast;
    }
  },
  actions: {
    loadEnv({ commit, state }, toast: ToastPluginApi) {
      console.log('load env action', state);
      commit('setBaseUrl', process.env.VUE_APP_ROOT_API || '/');
      commit('setToken', localStorage.getItem('token'));
      commit('setToast', toast);
    }
  },
  modules: {
    user: UserModule,
    lessons: LessonsModule,
    lesson: LessonModule,
    playlists: PlayListModule,
    playlist: PlaylistModule,
    devices: DevicesModule,
  }
})

