
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    callback: Function,
  },
})
export default class Timer extends Vue {
  duration = '0:00';
  total = 0;
  step = 5;
  timer!: any;
  callback!: any;

  onChangeTime(type: number) {
    this.total += type * 60;
    this.total = Math.max(0, this.total);
    this.duration = `${Math.floor(this.total / 60)}:${`${(this.total % 60)}`.padStart(2,"0")}`;
  }

  onCountDown() {
    clearInterval(this.timer);
    if(this.total <= 0){
      return;
    }
    this.timer = setInterval(() => {
      this.total--;
      this.duration = `${Math.floor(this.total / 60)}:${`${(this.total % 60)}`.padStart(2,"0")}`;
      if(this.total <= 0){
        clearInterval(this.timer);
        if(this.callback){
          this.callback();
        }
      }
      console.log('duration', this.duration, this.total);
    }, 1000);
  }
}
