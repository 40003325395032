import router from '@/router';
import store from '@/store';
import { Vue } from 'vue-class-component';

export default abstract class AuthenticationView extends Vue {
  async created() {
    await this.loadUserDetail();
    await this.onCustomCreated();
  }

  async loadUserDetail() {
    console.log('created at authentication');
    if (!store.state.token) {
      router.push({ path: 'signin' })
      return;
    }
    // console.log('user: ', store.getters('user/data'))
    await store.dispatch('user/getDetail');
  }
  abstract onCustomCreated(param?: any): Promise<void>;
}
