import { callAPICommon } from "@/classes/utils/api";

export const PlaylistModule = {
  namespaced: true,
  state: {
    data: {},
    lessons: [],
    currentLesson: undefined,
    index: 0,
  },
  mutations: {
    setPlaylist(state: any, playlist: any) {
      state.data = playlist;
      state.lessons = playlist.lessons || [];
      if (state.lessons.length) {

        state.currentLesson = state.lessons[state.index];
        console.log('currentLesson', state.currentLesson)
      }
    },
    selectLesson(state: any, index: number) {
      state.index = index;
      state.currentLesson = state.lessons[index];
    }
  },
  actions: {
    async getDetail({ commit }: any, id: string) {
      callAPICommon({ path: `api/v1/playlists/${id}?fields=lessons.`, method: 'get' }, {
        successCallback: (data: any) => {
          commit('setPlaylist', data);
        },
      })
    },

    async update({ commit }: any, payload: { id: string, subtitle: object[] }) {
      callAPICommon({ path: `api/v1/playlists/${payload.id}`, method: 'put', data: JSON.stringify({ subtitle: payload.subtitle }) })
    },
  },
  getters: {}
}
