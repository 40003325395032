export function normalizeUrl(url: string) {
  const groups = url.match(/^((http|https):\/\/)?(.*)$/);
  if (!groups)
    return url;
  const path = groups[3].replace(/\/+/, '/').replace(/^\/(.*)/, '$1');
  if (groups[1])
    return `${groups[1]}${path}`;
  return `/${path}`;
}

export async function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
