import { callAPICommon } from "@/classes/utils/api";
import { sleep } from "@/classes/utils/text.utils";
import router from "@/router";

export const PlayListModule = {
  namespaced: true,
  state: {
    data: [],
    myPlaylists: [],
    editing: undefined,
    totalPage: 1,
    page: 0,
    count: 0,
  },
  mutations: {
    setMyPlaylists(state: any, payload: any) {
      state.myPlaylists = payload.data;
    },

    setPlaylists(state: any, payload: any) {
      const playlists = payload.data || [];
      for (let index = 0; index < playlists.length; index++) {
        playlists[index].key = index + 1;
      }
      state.data = playlists;
    },

    setPlaylist(state: any, payload: { index: number, playlist: any }) {
      console.log('set playlist: ', payload.playlist);
      if (payload.index == -1) {
        if (state.data[0] && !state.data[0]?._id)
          return;
        const lessons = [{ editable: true, name: undefined, _id: undefined, audioPath: undefined, subtitle: undefined, key: 1 }, ...state.data];
        state.data = lessons.map((lesson: any, index: number) => {
          return { ...lesson, key: index + 1 }
        });
        state.editing = lessons[0];
        console.log('state.data: ', state.data);
        return;
      }
      if (state.data[payload.index] && !payload.playlist._id) {
        state.editing = undefined;
        state.data.splice(payload.index, 1);
        const lessons = state.data;
        state.data = lessons.map((lesson: any, index: number) => {
          return { ...lesson, key: index + 1 }
        });
        return;
      }
      if (state.data[payload.index]) {
        state.data[payload.index] = payload.playlist;
        state.editing = payload.playlist.editable ? payload.playlist : undefined;
        console.log('update editing: ', state.editing, payload.playlist);
        // state.data = lessons;
      } else {
        state.editing = undefined;
      }
    }

  },
  actions: {
    async getMyPlaylist({ commit, state, rootGetters, rootState }: any) {
      while (!rootState.user.data) {
        await sleep(200);
      }
      callAPICommon({ path: `api/v1/playlists?createBy=${rootState.user.data._id}&size=-1&fields=_id,name`, method: 'get' }, {
        successCallback: (data: any) => {
          commit('setMyPlaylists', data);
        },
      })
    },
    async getAll({ commit, state, rootGetters, rootState }: any) {
      callAPICommon({ path: `api/v1/playlists?fields=lessons._id,lessons.name`, method: 'get' }, {
        successCallback: (data: any) => {
          commit('setPlaylists', data);
        },
      })
    },
    async add({ commit }: any) {
      commit('setPlaylist', { index: -1 });
    },
    async create({ commit }: any, payload: { name: string, index: number }) {
      console.log('create: ', payload);
      callAPICommon({ path: 'api/v1/playlists/', method: 'post', data: JSON.stringify(payload) }, {
        successCallback: (data: any) => {
          commit('setPlaylist', { index: payload.index, playlist: { ...data, key: payload.index + 1 } });
        },
      })
    },

    async update({ commit }: any, payload: { name: string, _id: string, index: number, key: string }) {
      callAPICommon({ path: `api/v1/playlists/${payload._id}`, method: 'put', data: JSON.stringify({ name: payload.name }) }, {
        successCallback: (data: any) => {
          console.log(data);
          commit('setPlaylist', { index: payload.index, playlist: { ...data, key: payload.key } });
        },
      })
    },

    async invite({ state }: any, payload: { _id: string, email: string }) {
      const rs = await callAPICommon({ path: `api/v1/playlists/${payload._id}/invited`, method: 'put', data: JSON.stringify({ email: payload.email }) }, {
        successCallback: (data: any) => {
          console.log(data);
        },
      })
      return !!rs;
    }


  },
  getters: {

  }
}
