
import { sleep } from "@/classes/utils/text.utils";
import store from "@/store";
import { ref } from "vue";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    sentences: [Object],
    audioUrl: String,
    playerHeight: String,
    repeat: Boolean,
    autoStart: Boolean,
    id: String,
    isStop: Boolean,
  },
  watch: {
    currentIndex(e) {
      this.onUpdateCurrentIndex();
    },
    audioUrl(e) {
      this.currentIndex = 0;
    },
    isStop(e) {
      // this.audio.pause();
      console.log('update is stop', e);
      if(e && this.audio){
        this.audio.pause();
      }
    }
  },
})
export default class PlayWithSubtitle extends Vue {
  sentences!: { start: number; end: number; content: string; note: string }[];
  audioUrl!: string;
  currentIndex = 0;
  currentTime = 0;
  id!: string;
  note = "";
  repeat!: boolean;
  autoStart!: boolean;
  playerHeight!: string;
  sentenceElements = ref([]);
  audio: any = ref(null);
  enableNote = false;

  onNoteSubmit() {
    this.sentences[this.currentIndex].note = this.note;
    store.dispatch("lesson/update", { id: this.id, subtitle: this.sentences });
  }

  onTimeUpdateListener(): void {
    // Update current time
    this.currentTime = this.audio?.currentTime || 0;
    if (this.currentTime < this.sentences[this.currentIndex].start) {
      this.currentIndex = 0;
    }
    for (let index = this.currentIndex; index < (this.sentences || []).length; index++) {
      const sentence = this.sentences[index];
      if (sentence.start > this.currentTime) {
        this.currentIndex = Math.max(this.currentIndex, index - 1);
        break;
      }
      this.currentIndex = index;
    }
  }

  onClickSentence(index: number) {
    if (!this.sentences[index]) return;
    if (!this.audio) return;
    this.audio.currentTime = this.sentences[index].start;
    this.currentIndex = index;
    if (this.audio.paused) {
      setTimeout(() => {
        if (!this.enableNote) this.audio.play();
      }, 100);
    }
  }

  onUpdateCurrentIndex() {
    document
      .getElementById(`sentence-${this.currentIndex}`)
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
    this.note = this.sentences[this.currentIndex].note;
    this.enableNote = false;
  }

  onNoteClick() {
    console.log("pause");
    this.enableNote = true;
    this.audio.pause();
  }

  onEnded() {
    console.log("on ended", this.repeat);
    this.$emit("ended", true);
  }

  async onLoaded() {
    if (this.enableNote) return;
    console.log("on loaded", this.repeat, this.enableNote);
  }
}
