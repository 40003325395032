
import { Options, Vue } from 'vue-class-component';
import AuthenticatorView from './AuthenticatorView';
import PlayWithSubtitle from '@/components/common/PlayWithSubtitle.vue';

@Options({
  components: {
    PlayWithSubtitle,
  },
})
export default class ListenView extends AuthenticatorView {
  sentences: any[] = []
  audioUrl = "https://elt.oup.com/elt/students/selectreadings2e/audio/elementary/sr_elem_ch01.mp3?cc=vn&selLanguage=en"

  async onCustomCreated() {
    console.log('created at listionview')
    for (let index = 0; index < 20; index++) {
      this.sentences.push({ start: index * 2, end: index * 2 + 1, content: `sentence ${index}` })
    }
  }

}

