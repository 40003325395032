
import { Options } from 'vue-class-component';
import store from '@/store';
import AuthenticatorView from '../AuthenticatorView';
import { mapState } from 'vuex';
import type { UploadChangeParam } from 'ant-design-vue';
import { normalizeUrl } from '@/classes/utils/text.utils';
import { ref } from 'vue';

@Options({
  components: {
  },
  computed: { ...mapState(['lessons', 'baseUrl', 'token', 'playlists']) },
})
export default class LessonListView extends AuthenticatorView {
  lessons!: any;
  baseUrl!: any;
  token!: any;
  playlists!: any;
  fileList = [];
  emailInvite = '';
  oldItem = undefined;
  columns = [
    {
      title: 'Order',
      dataIndex: 'key',
      key: 'key',
      fixed: true,
      resizable: false,
      width: 75,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: true,
      resizable: true,
      width: 200,
      minWidth: 100,
      maxWidth: 300,
    },
    {
      title: 'Audio',
      dataIndex: 'audioPath',
      key: 'audio',
      resizable: false,
      width: 200,
    },
    {
      title: 'Subtitle',
      dataIndex: 'subtitle',
      key: 'subtitle',
      resizable: false,
      width: 300,
    },
    {
      title: 'Playlist',
      key: 'playlist',
      dataIndex: 'playlist',
      resizable: false,
      width: 300,
    },
    {
      title: 'Action',
      key: 'action',
      resizable: true,
      width: 100,
      minWidth: 100,
      maxWidth: 250,
    },
  ];
  recordToInvite = undefined;
  showInvite = false;

  playlistOptions() {
    return this.playlists.myPlaylists.map((p: any) => {
      return {
        label: p.name,
        value: p._id,
      }
    })
  }

  async onCustomCreated() {
    store.dispatch('lessons/getAll');
    store.dispatch('playlists/getMyPlaylist');
  }

  toUrl(url: string) {
    const txt = normalizeUrl(url);
    return txt;
  }

  onChangePage(data: any) {
    console.log('one change page: ', data);
  }

  handleChangeUploadFile(info: UploadChangeParam, record: any) {
    console.log('info: ', info, record);
    if (info.file.status == 'done') {
      store.commit('lessons/setLesson', { index: record.key - 1, lesson: { ...info?.file?.response, key: record.key, editable: false } });
    }
  }

  handleEditable(record: any) {
    const isCancel = !!record.editable;
    console.log('is cancel: ', isCancel)
    store.commit('lessons/setLesson', { index: record.key - 1, lesson: { ...record, ...(this.oldItem || {}), key: record.key, editable: !record.editable } });
    if (isCancel) {
      this.oldItem = undefined;
    } else {
      this.oldItem = { ...record };
    }
  }

  handleAddRow() {
    store.dispatch('lessons/add');
  }

  handleSave(record: any) {
    if (record._id) {
      store.dispatch('lessons/update', { _id: record._id, name: record.name, playlists: record.playlist_ids, index: record.key - 1, key: record.key });
    } else {
      store.dispatch('lessons/create', { name: record.name, index: record.key - 1 });
    }
    this.oldItem = undefined;
    console.log('record: ', record);
    // store.dispatch('lessons/create', record);
  }


  async handleInviteSubmit(record: any) {
    if (!this.emailInvite) {
      this.$toast.warning('The email is not empty!!');
      return;
    }
    console.log('submit', this.emailInvite, this.recordToInvite);
    const rs = await store.dispatch('lessons/invite', { _id: this.recordToInvite, email: this.emailInvite });
    console.log('rs: ', rs);
    store.dispatch('lessons/setLessons', { index: record.key - 1, playlist: { ...record, showInvite: !rs } });
  }

  handleClickInvited(record: any) {
    console.log('record: ', record);
    this.recordToInvite = record._id;
    this.emailInvite = '';
    store.dispatch('lessons/setLessons', { index: record.key - 1, playlist: { ...record, showInvite: true } });
    // store.dispatch('lessons/create', record);
  }

}

