import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "playlist" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TimerView = _resolveComponent("TimerView")!
  const _component_PlayWithSubtitle = _resolveComponent("PlayWithSubtitle")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, "Playlist " + _toDisplayString(_ctx.playlist.data?.name), 1),
    _createVNode(_component_a_tabs, {
      class: "tab-wrap",
      activeKey: _ctx.activeKey,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
      "tab-position": "bottom",
      animated: "",
      style: _normalizeStyle(`height: ${_ctx.bodyHeight + 62}px;`)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_tab_pane, {
          key: "1",
          tab: `Playing ${_ctx.playlist.currentLesson?.name}`
        }, {
          default: _withCtx(() => [
            _createVNode(_component_PlayWithSubtitle, {
              playerHeight: `${_ctx.bodyHeight}px`,
              audioUrl: _ctx.toUrl(),
              sentences: _ctx.playlist.currentLesson?.subtitle,
              isStop: _ctx.isStop,
              autoStart: true,
              id: _ctx.playlist.currentLesson?._id,
              onEnded: _ctx.onEnded
            }, {
              timer: _withCtx(() => [
                _createVNode(_component_TimerView, { callback: _ctx.stopAudio }, null, 8, ["callback"])
              ]),
              _: 1
            }, 8, ["playerHeight", "audioUrl", "sentences", "isStop", "id", "onEnded"])
          ]),
          _: 1
        }, 8, ["tab"]),
        _createVNode(_component_a_tab_pane, {
          key: "2",
          tab: "Lessons"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playlist.lessons, (lesson, index) => {
              return (_openBlock(), _createElementBlock("p", {
                key: `lesson-${index}`,
                class: _normalizeClass({ active: index == _ctx.playlist.index, lesson: true }),
                onClick: () => _ctx.selectLesson(index)
              }, _toDisplayString(lesson.name), 11, _hoisted_2))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["activeKey", "style"])
  ]))
}