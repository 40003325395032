
import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import type { Rule } from 'ant-design-vue/es/form';
import store from '@/store';

interface FormState {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}


@Options({
  components: {
  },
})
export default class SignupView extends Vue {
  registerActive = false;

  formState = reactive<FormState>({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  async validateConfirmPass(_rule: Rule, value: string) {
    if (value === '') {
      return Promise.reject('Please input the password again');
    } else if (value !== this.formState.password) {
      return Promise.reject("The Password and the confirm password don't match!");
    } else {
      return Promise.resolve();
    }

  }


  onFinish(param?: any) {
    console.log('on finish', param);
    store.dispatch('user/signup', param);
  }

  onFinishFailed(param?: any) {
    console.log('on finish failed');
  }
}
