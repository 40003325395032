import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/listen',
    name: 'listen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ListenView.vue')
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import('../views/auth/SigninView.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/auth/SignupView.vue')
  },
  {
    path: '/lessons',
    name: 'lessons',
    component: () => import('../views/lessons/LessonListView.vue')
  },
  {
    path: '/lessons/:id',
    name: 'lesson',
    component: () => import('../views/lessons/LessonDetailView.vue')
  },
  {
    path: '/playlists',
    name: 'playlists',
    component: () => import('../views/playlists/PlaylistListView.vue')
  },
  {
    path: '/playlists/:id',
    name: 'playlist',
    component: () => import('../views/playlists/PlaylistDetailView.vue')
  },
  {
    path: '/devices/',
    name: 'devices',
    component: () => import('../views/devices/DeviceListView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
