
import { Options } from 'vue-class-component';
import store from '@/store';
import AuthenticatorView from '../AuthenticatorView';
import { mapState } from 'vuex';
import type { UploadChangeParam } from 'ant-design-vue';
import { normalizeUrl } from '@/classes/utils/text.utils';
import { ref } from 'vue';

@Options({
  components: {
  },
  computed: { ...mapState(['playlists', 'baseUrl', 'token']) },
})
export default class PlaylistListView extends AuthenticatorView {
  playlists!: any;
  baseUrl!: any;
  token!: any;
  fileList = [];
  emailInvite = '';
  oldItem = undefined;
  columns = [
    {
      title: 'Order',
      dataIndex: 'key',
      key: 'key',
      fixed: true,
      resizable: false,
      width: 75,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: true,
      resizable: true,
      width: 200,
      minWidth: 100,
      maxWidth: 300,
    },
    {
      title: 'Lessons',
      dataIndex: 'lessons',
      key: 'lessons',
    },
    {
      title: 'Action',
      key: 'action',
      resizable: true,
      width: 250,
      minWidth: 100,
      maxWidth: 250,
    },
  ];
  recordToInvite = undefined;
  showInvite = false;

  async onCustomCreated() {
    store.dispatch('playlists/getAll');
  }

  toUrl(url: string) {
    const txt = normalizeUrl(url);
    return txt;
  }

  onChangePage(data: any) {
    console.log('one change page: ', data);
  }

  handleEditable(record: any) {
    const isCancel = !!record.editable;
    console.log('is cancel: ', isCancel)
    store.commit('playlists/setPlaylist', { index: record.key - 1, playlist: { ...record, ...(this.oldItem || {}), key: record.key, editable: !record.editable } });
    if (isCancel) {
      this.oldItem = undefined;
    } else {
      this.oldItem = { ...record };
    }
  }

  handleAddRow() {
    store.dispatch('playlists/add');
  }

  handleSave(record: any) {
    if (record._id) {
      store.dispatch('playlists/update', { _id: record._id, name: record.name, index: record.key - 1, key: record.key });
    } else {
      store.dispatch('playlists/create', { name: record.name, index: record.key - 1 });
    }
    this.oldItem = undefined;
    console.log('record: ', record);
    // store.dispatch('playlists/create', record);
  }


  async handleInviteSubmit(record: any) {
    if (!this.emailInvite) {
      this.$toast.warning('The email is not empty!!');
      return;
    }
    console.log('submit', this.emailInvite, this.recordToInvite);
    const rs = await store.dispatch('playlists/invite', { _id: this.recordToInvite, email: this.emailInvite });
    console.log('rs: ', rs);
    store.commit('playlists/setPlaylist', { index: record.key - 1, playlist: { ...record, showInvite: !rs } });
  }

  handleClickInvited(record: any) {
    console.log('record: ', record);
    this.recordToInvite = record._id;
    this.emailInvite = '';
    // store.dispatch('playlists/create', record);
    store.commit('playlists/setPlaylist', { index: record.key - 1, playlist: { ...record, showInvite: true } });
  }

}

