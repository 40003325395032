import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import axios from './classes/plugins/axios'

createApp(App).use(ToastPlugin, {
  position: 'top-right'
}).use(axios, {
  baseUrl: process.env.VUE_APP_ROOT_API || '/',
}).use(Antd).use(store).use(router).mount('#app')
