import { callAPICommon } from "@/classes/utils/api";

export const LessonModule = {
  namespaced: true,
  state: {
    data: {},
  },
  mutations: {
    setLesson(state: any, lesson: any) {
      state.data = lesson;
    }
  },
  actions: {
    async getDetail({ commit }: any, id: string) {
      callAPICommon({ path: `api/v1/lessons/${id}`, method: 'get' }, {
        successCallback: (data: any) => {
          commit('setLesson', data);
        },
      })
    },

    async update({ commit }: any, payload: { id: string, subtitle: object[] }) {
      callAPICommon({ path: `api/v1/lessons/${payload.id}`, method: 'put', data: JSON.stringify({ subtitle: payload.subtitle }) })
    },
  },
  getters: {}
}
