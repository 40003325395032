
import { Options } from "vue-class-component";
import store from "@/store";
import AuthenticatorView from "../AuthenticatorView";
import { mapState } from "vuex";
import PlayWithSubtitle from "@/components/common/PlayWithSubtitle.vue";
import Timer from "@/components/common/TimerView.vue";

import { normalizeUrl } from "@/classes/utils/text.utils";

@Options({
  components: {
    PlayWithSubtitle,
    Timer,
  },
  computed: { ...mapState(["lesson", "baseUrl", "token"]) },
})
export default class LessonDetailView extends AuthenticatorView {
  lesson!: any;
  baseUrl!: string;
  bodyHeight = 500;
  isStop = false;

  stopAudio(){
    console.log('stop');
    this.isStop = true;
  }

  toUrl() {
    return normalizeUrl(`${this.baseUrl}${this.lesson.data?.audioPath}`);
  }

  async onCustomCreated() {
    store.dispatch("lesson/getDetail", this.$route.params.id);
    this.bodyHeight = screen.availHeight - 300;
  }
}
