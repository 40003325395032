import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { id: "subtitle" }
const _hoisted_2 = ["id", "onClick"]
const _hoisted_3 = { id: "timer" }
const _hoisted_4 = { id: "audio" }
const _hoisted_5 = ["src", "loop", "autoplay"]
const _hoisted_6 = { id: "note" }
const _hoisted_7 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_input_group = _resolveComponent("a-input-group")!

  return (_openBlock(), _createElementBlock("div", {
    id: "player",
    style: _normalizeStyle({ height: _ctx.playerHeight || '500px' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sentences || [], (sentence, index) => {
        return (_openBlock(), _createElementBlock("p", {
          key: `sentence-${index}`,
          id: `sentence-${index}`,
          onClick: ($event: any) => (_ctx.onClickSentence(index)),
          class: _normalizeClass({ active: index == _ctx.currentIndex, sentence: true })
        }, [
          _createTextVNode(_toDisplayString(sentence.content) + " ", 1),
          (index == _ctx.currentIndex)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 0,
                type: "link",
                class: "note",
                onClick: _ctx.onNoteClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Note")
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ], 10, _hoisted_2))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "timer")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("audio", {
        controls: "",
        src: _ctx.audioUrl,
        ref: "audio",
        onTimeupdate: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onTimeUpdateListener && _ctx.onTimeUpdateListener(...args))),
        onCanplay: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onLoaded && _ctx.onLoaded(...args))),
        loop: _ctx.repeat,
        autoplay: _ctx.autoStart,
        onEnded: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onEnded && _ctx.onEnded(...args)))
      }, null, 40, _hoisted_5)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_a_input_group, { compact: "" }, {
        default: _withCtx(() => [
          (_ctx.enableNote)
            ? (_openBlock(), _createBlock(_component_a_textarea, {
                key: 0,
                rows: 6,
                value: _ctx.note,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.note) = $event))
              }, null, 8, ["value"]))
            : _createCommentVNode("", true),
          (_ctx.enableNote)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 1,
                type: "primary",
                class: "submit-note",
                onClick: _ctx.onNoteSubmit
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Update")
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (!_ctx.enableNote)
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.note), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ], 4))
}