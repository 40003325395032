import { callAPICommon } from "@/classes/utils/api";
import router from "@/router";

export const LessonsModule = {
  namespaced: true,
  state: {
    data: [],
    editing: undefined,
    totalPage: 1,
    page: 0,
    count: 0,
  },
  mutations: {
    setLessons(state: any, payload: any) {
      const lessons = payload.data;
      for (let index = 0; index < lessons.length; index++) {
        lessons[index].key = index + 1;
        lessons[index].playlists = lessons[index].playlists || [];
        lessons[index].playlist_ids = lessons[index].playlists.map((p: any) => p._id);
      }
      state.data = lessons;
      state.totalPage = payload.meta.totalPages;
      state.count = payload.meta.count;
    },

    setLesson(state: any, payload: { index: number, lesson: any }) {
      // const lessons = state.data;
      console.log('set lesson: ', payload.lesson);
      if (payload.index == -1) {
        if (state.data[0] && !state.data[0]?._id)
          return;
        const lessons = [{ editable: true, name: undefined, _id: undefined, audioPath: undefined, subtitle: undefined, key: 1 }, ...state.data];
        state.data = lessons.map((lesson: any, index: number) => {
          return { ...lesson, key: index + 1 }
        });
        state.editing = lessons[0];
        console.log('state.data: ', state.data);
        return;
      }
      if (state.data[payload.index] && !payload.lesson._id) {
        state.editing = undefined;
        state.data.splice(payload.index, 1);
        const lessons = state.data;
        state.data = lessons.map((lesson: any, index: number) => {
          return { ...lesson, key: index + 1 }
        });
        return;
      }
      if (state.data[payload.index]) {
        payload.lesson.playlist_ids = (payload.lesson.playlists || []).map((p: any) => p._id)
        state.data[payload.index] = payload.lesson;
        state.editing = payload.lesson.editable ? payload.lesson : undefined;
        console.log('update editing: ', state.editing, payload.lesson);
        // state.data = lessons;
      } else {
        state.editing = undefined;
      }
    }
  },
  actions: {
    async getAll({ commit }: any) {
      callAPICommon({ path: 'api/v1/lessons?fields=playlists.name', method: 'get' }, {
        successCallback: (data: any) => {
          commit('setLessons', data);
        },
      })
    },
    async add({ commit }: any) {
      commit('setLesson', { index: -1 });
    },
    async create({ commit }: any, payload: { name: string, index: number }) {
      console.log('create: ', payload);
      callAPICommon({ path: 'api/v1/lessons/', method: 'post', data: JSON.stringify(payload) }, {
        successCallback: (data: any) => {
          commit('setLesson', { index: payload.index, lesson: { ...data, key: payload.index + 1 } });
        },
      })
    },

    async update({ commit }: any, payload: { name: string, _id: string, index: number, key: string, playlists: string[] }) {
      callAPICommon({ path: `api/v1/lessons/${payload._id}?fields=playlists.name`, method: 'put', data: JSON.stringify({ name: payload.name, playlists: payload.playlists }) }, {
        successCallback: (data: any) => {
          console.log(data);
          commit('setLesson', { index: payload.index, lesson: { ...data, key: payload.key } });
        },
      })
    },

    async invite({ state }: any, payload: { _id: string, email: string }) {
      const rs = await callAPICommon({ path: `api/v1/lessons/${payload._id}/invited`, method: 'put', data: JSON.stringify({ email: payload.email }) }, {
        successCallback: (data: any) => {
          console.log(data);
        },
      })
      return !!rs;
    }


  },
  getters: {}
}
