import { callAPICommon } from "@/classes/utils/api";
import router from "@/router";

export const UserModule = {
  namespaced: true,
  state: {
    data: undefined
  },
  mutations: {
    setUser(state: any, payload: any) {
      console.log('set user')
      state.data = payload;
    }
  },
  actions: {
    async login({ commit, rootState }: any, payload: { email: string, password: string }) {
      callAPICommon({ path: 'api/v1/users/signin', method: 'post', data: JSON.stringify(payload) }, {
        successCallback: (data: any) => {
          console.log('data: ', data);
          localStorage.setItem('token', data.token);
          commit('setToken', data.token, { root: true });
          rootState.toast.success('Signin complete');
          router.push({ name: 'home' });
        },
      })
    },
    async signup({ commit, rootState }: any, payload: { email: string, password: string, name: string }) {
      callAPICommon({ path: 'api/v1/users/signup', method: 'post', data: JSON.stringify(payload) }, {
        successCallback: (data: any) => {
          console.log('data: ', data);
          // localStorage.setItem('token', data.token);
          // commit('setToken', data.token, { root: true });
          rootState.toast.success('Signup complete');
          router.push({ name: 'signin' });
        },
      })
    },
    async getDetail({ commit, state }: any) {
      if (state.data) {
        return;
      }
      callAPICommon({ path: 'api/v1/users/me', method: 'get' }, {
        successCallback: (data: any) => {
          commit('setUser', data);
        },
      })
    },
    logout({ commit }: any) {
      localStorage.removeItem('token');
      commit('setToken', undefined, { root: true });
      commit('setUser', undefined);
      router.push({ name: 'signin' });
    }

  },
  getters: {
    detail(state: any) {
      console.log('detail at getters', state);
      return 1;
    }
  }
}
