import router from '@/router';
import store from '@/store';
import axios, { AxiosError, AxiosResponse } from 'axios'

/**
 * Call Axios Request, return a standardized Axios Response
 *
 * @return Standardized Axios Response
 */
export async function callAPICommon(
  request: { path: string, method: string, data?: any, headers?: any },
  options: { successCallback?: any, errorCallback?: any, showError?: boolean, throwError?: boolean, prefixError?: string } = { showError: true, throwError: false, prefixError: '' }
): Promise<AxiosResponse | any> {
  try {
    const result = await axios({
      baseURL: store.state.baseUrl,
      url: request.path,
      method: request.method,
      headers: { "Content-Type": 'application/json; charset=utf-8', 'Authorization': store.state.token || '', ...request.headers },
      data: request.data,
    });
    if (options.successCallback) {
      options.successCallback(result.data);
    }
    return result;
  } catch (e) {
    console.log('has error at call api common')
    const err = e as any;
    if (err.response?.status == 401) {
      store.state.toast.warning('token is expired');
      store.dispatch('user/logout');
      return;
    }
    console.log('showError: ', options.showError);
    if (options.showError == undefined || options.showError) {
      store.state.toast.warning(`${options.prefixError || ''} '${err.response?.data?.message || err.toString()}'`);
    }
    if (options.throwError)
      throw e;
    return;
  }
}
