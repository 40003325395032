
import { Options } from 'vue-class-component';
import store from '@/store';
import { mapState } from 'vuex';
import AuthenticatorView from '@/views/AuthenticatorView';
import { Socket, io } from "socket.io-client";

@Options({
  components: {
  },
  computed: { ...mapState(['baseUrl', 'token']) },
})
export default class SocketView extends AuthenticatorView {
  baseUrl!: any;
  token!: any;
  socket: any;

  async onCustomCreated() {
    console.log('created', this.baseUrl);
    this.socket = io(this.baseUrl, {
      autoConnect: true, reconnection: true, extraHeaders: {
        authorization: this.token,
      }
    });
    this.socket.on("connect", () => {
      console.log('Connected');
    });

    this.socket.on("disconnect", () => {
      console.log('disconnect')
    });

    this.socket.on("events", (args: any[]) => {
      // console.log('events', args)
      store.commit('devices/setDevice', args);
    });


  }
}
