import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "timer" }
const _hoisted_2 = { class: "control" }
const _hoisted_3 = { class: "start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_input = _resolveComponent("a-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_button, {
        class: "btn",
        onClick: _cache[0] || (_cache[0] = 
          () => {
            _ctx.onChangeTime(-5);
          }
        )
      }, {
        default: _withCtx(() => [
          _createTextVNode("-5")
        ]),
        _: 1
      }),
      _createVNode(_component_a_button, {
        class: "btn",
        onClick: _cache[1] || (_cache[1] = 
          () => {
            _ctx.onChangeTime(-1);
          }
        )
      }, {
        default: _withCtx(() => [
          _createTextVNode("-1")
        ]),
        _: 1
      }),
      _createVNode(_component_a_input, {
        value: _ctx.duration,
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.duration) = $event)),
        disabled: "",
        class: "duration"
      }, null, 8, ["value"]),
      _createVNode(_component_a_button, {
        class: "btn",
        onClick: _cache[3] || (_cache[3] = 
          () => {
            _ctx.onChangeTime(1);
          }
        )
      }, {
        default: _withCtx(() => [
          _createTextVNode("+1")
        ]),
        _: 1
      }),
      _createVNode(_component_a_button, {
        class: "btn",
        onClick: _cache[4] || (_cache[4] = 
          () => {
            _ctx.onChangeTime(5);
          }
        )
      }, {
        default: _withCtx(() => [
          _createTextVNode("+5")
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_button, {
        class: "btn",
        onClick: _ctx.onCountDown
      }, {
        default: _withCtx(() => [
          _createTextVNode("Count down")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}