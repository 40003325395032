
import { Vue } from 'vue-class-component';
import store from './store';

export default class App extends Vue {

  mounted() {
    store.dispatch('loadEnv', this.$toast);
    // this.$toast.warning('token is expired')
  }

}

