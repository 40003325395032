
import { Options } from 'vue-class-component';
import store from '@/store';
import AuthenticatorView from '../AuthenticatorView';
import { mapState } from 'vuex';
import SocketView from '@/components/common/SocketView.vue';
import Icon, { SettingOutlined } from '@ant-design/icons-vue';
import { UnwrapRef, reactive } from 'vue';
import moment from "moment";
import "vue-material-time-picker/dist/style.css";

interface FormGPIOState {
  name: string;
  pin: number;
  mode: number;
  performAt: number;
  currentState: number;
  duration: number;
  startAt: number[];
  id: string;
  gpioIndex: number;
  key: number;
}

interface FormEditInfo {
  name: string;
  id: string;
  key: number;
}


@Options({
  components: {
    SocketView,
    SettingOutlined,
    Icon,
  },
  computed: { ...mapState(['devices', 'baseUrl', 'token']) },
})
export default class PlaylistListView extends AuthenticatorView {
  devices!: any;
  baseUrl!: any;
  token!: any;
  fileList = [];
  emailInvite = '';
  dataTemp: any = {};
  time = '00:00';
  settingGPIO = 'Hello world';
  formGPIOState: UnwrapRef<FormGPIOState> = reactive({
    name: '',
    pin: 0,
    mode: 0,
    performAt: 0,
    currentState: 0,
    duration: 0,
    startAt: [0, 0, 0],
    id: '',
    gpioIndex: 0,
    key: 0,
  });

  formEditInfo: UnwrapRef<FormEditInfo> = reactive({
    name: '',
    id: '',
    key: 0,
  });

  columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      fixed: true,
      resizable: false,
      width: 25,
    },
    {
      title: 'GPIO',
      // dataIndex: 'gpios',
      key: 'gpios',
    },
    {
      title: 'SSID',
      dataIndex: 'ssid',
      key: 'ssid',
    },
    {
      title: 'Ip Address',
      dataIndex: 'ip',
      key: 'ip',
    },
  ];
  recordToInvite = undefined;
  showModal = false;
  showModalEditInfo = false;
  showEditInfo(record: any) {
    console.log('record: ', record, record.key);
    this.showModalEditInfo = true;
    this.formEditInfo = reactive({
      ...record, id: record._id,
      key: record.key,
    });
  }

  showGPIOInfo(record: any, gpioIndex: number) {
    console.log('record: ', record, record.key, gpioIndex, record.duration, moment(300000).utc().format('mm:ss'));
    this.showModal = true;
    this.formGPIOState = reactive({
      ...record.gpios[gpioIndex], id: record._id,
      performAt: moment(record.gpios[gpioIndex].performAt).utc().format('HH:mm:ss'),
      duration: moment(record.gpios[gpioIndex].duration).utc().format('HH:mm:ss'),
      startAt: record.gpios[gpioIndex].startAt.map((e: number) => moment(e).utc().format('HH:mm:ss')),
      key: record.key,
      gpioIndex,
    });
  }

  hideModal() {
    this.showModal = false;
  }

  hideModalEditInfo() {
    this.showModalEditInfo = false;
  }

  submit() {
    console.log('this.formGPIOState: ', this.formGPIOState);
    store.dispatch('devices/settingGPIO', {
      ...this.formGPIOState,
      duration: new Date(`Thu Jan 01 1970 ${this.formGPIOState.duration}Z`).getTime(),
      startAt: this.formGPIOState.startAt.map((e) => new Date(`Thu Jan 01 1970 ${e}Z`).getTime()),
      callback: this.hideModal,
    });
  }

  updateMetata() {
    console.log('this.formEditInfo: ', this.formEditInfo);
    store.dispatch('devices/update', {
      ...this.formEditInfo,
      callback: this.hideModalEditInfo,
    });
  }


  async onCustomCreated() {
    store.dispatch('devices/getAll');
  }

  onChangePage(data: any) {
    console.log('one change page: ', data);
  }

  onChangeStatus(record: any, gpioIndex: number, value: any) {
    console.log('value', gpioIndex, record, value);
    store.dispatch('devices/updateState', { _id: record._id, currentState: +value, gpioIndex });
  }

}

