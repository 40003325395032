import { callAPICommon } from "@/classes/utils/api";

export const DevicesModule = {
  namespaced: true,
  state: {
    data: [],
    totalPage: 1,
    page: 0,
    count: 0,
  },
  mutations: {
    setDevices(state: any, payload: any) {
      state.data = payload.data.map((device: any, index: number) => {
        return { ...device, relayStatus1: payload.relayStatus1 == 1, relayStatus2: payload.relayStatus2 == 1, key: index + 1 }
      });
      state.totalPage = payload.meta.totalPages;
      state.count = payload.meta.count;
    },

    setDevice(state: any, payload: any) {

      let found = false;
      const devices = state.data;
      for (let index = 0; index < devices.length; index++) {
        const device = devices[index];
        if (device._id == payload._id) {
          devices[index] = { ...payload, relayStatus1: payload.relayStatus1 == 1, relayStatus2: payload.relayStatus2 == 1, key: index + 1 };
          found = true;
          break;
        }
      }
      if (!found)
        devices.push({ ...payload, key: devices.length + 1 });
      state.data = devices;
      // console.log('payload: ', payload, found);
    }
  },
  actions: {
    async getAll({ commit }: any) {
      callAPICommon({ path: 'api/v1/devices', method: 'get' }, {
        successCallback: (data: any) => {
          commit('setDevices', data);
        },
      })
    },

    async updateState({ commit }: any, payload: any) {
      const { _id, currentState, gpioIndex } = payload;
      console.log('update state: ', payload);
      const data: any = {
        [`gpios.${gpioIndex}.currentState`]: currentState,
        type: 'remote',
        index: gpioIndex,
      };
      callAPICommon({ path: `api/v1/devices/${_id}`, method: 'put', data }, {
        successCallback: (data: any) => {
          commit('setDevice', data);

        },
      })
    },

    async update({ commit }: any, payload: any) {
      const { _id, name, callback } = payload;
      console.log('update state: ', payload);
      const data: any = {
        name,
      };
      callAPICommon({ path: `api/v1/devices/${_id}`, method: 'put', data }, {
        successCallback: (data: any) => {
          commit('setDevice', data);
          callback();
        },
      })
    },

    async settingGPIO({ commit }: any, payload: any) {
      console.log('payload at setting', payload);
      const { id, key, duration, startAt, name, gpioIndex, callback, mode } = payload;
      const data: any = {
        [`gpios.${gpioIndex}.duration`]: duration,
        [`gpios.${gpioIndex}.startAt`]: startAt,
        [`gpios.${gpioIndex}.name`]: name,
        // [`gpios.${gpioIndex}.mode`]: mode,
      };

      callAPICommon({ path: `api/v1/devices/${id}`, method: 'put', data: { ...data, type: 'setting', index: +gpioIndex } }, {
        successCallback: (data: any) => {
          commit('setDevice', data);
          callback();
        },
      })
    }
  },
  getters: {}
}
